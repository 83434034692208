import {withGlobal} from '../shared/app'

const Footer = ({lang, setLang, env, navbarHidden}) => {
  const langs = (process.env.LANGS || '').split(',')
  env.debug && langs.push('xx')

  return (
    <div className='footer'>
      <div className='d-flex align-items-center justify-content-between bg-white mt-5 p-2'>
        <div className=''>
          <small>© InMemori {new Date().getFullYear()}</small>
        </div>
        <div className='d-flex justify-content-end align-items-center'>
          {langs.map((lng, i) => (
            <button
              key={i}
              onClick={setLang(lng)}
              className={`badge ${lang == lng ? 'badge-secondary' : ''} text-light ml-2`}
            >
              {lng}
            </button>
          ))}
        </div>
      </div>

      <style jsx>
        {`
          .footer > div {
            border-top: 1px solid #dadada;
          }
          .footer > div > div {
            flex: 1;
          }

          .badge {
            border: none;
            border-radius: 3px;
          }

          @media (max-width: 767px) {
            // md down
            .footer {
              margin-bottom: ${navbarHidden ? '0px' : '70px'};
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Footer)
